import {
  SlotResource,
  SlotAvailability,
  QueryAvailabilityResponse,
} from '@wix/ambassador-availability-calendar/types';
import { ServiceLocationType } from '@wix/bookings-uou-types';

function toTime(num: number) {
  const s = '00' + num;
  return s.substr(s.length - 2) + ':00';
}

export function createDummySlots(
  date: Date = new Date(),
): QueryAvailabilityResponse {
  date.setFullYear(date.getFullYear() + 1);
  const createSlot = ({
    id,
    scheduleId,
    date,
    startTime,
    endTime,
    location,
    resource,
  }: {
    id: string;
    scheduleId: string;
    date: string;
    startTime: string;
    endTime: string;
    location: any;
    resource: SlotResource;
  }) => {
    return {
      id,
      scheduleId,
      start: `${date}T${startTime}:00.000Z`,
      end: `${date}T${endTime}:00.000Z`,
      location,
      resource,
    };
  };

  const availabilityEntries = new Array(18).fill('00:00').map(
    (_, index): SlotAvailability => {
      date.setDate(index + 1);
      const dateISOString = date.toISOString();
      const day = dateISOString.substr(0, dateISOString.indexOf('T'));
      return {
        isBookable: true,
        spotsOpen: 5,
        spotsTotal: 10,
        slot: createSlot({
          id: `${index}`,
          scheduleId: `123`,
          date: day,
          startTime: toTime(index),
          endTime: toTime(index + 1),
          location: {
            locationType: ServiceLocationType.OWNER_BUSINESS,
            formattedAddress: 'Shlomo Ibn Gabirol Street 114',
            id: 'location-1-id',
            name: 'Tel Aviv',
          },
          resource: {
            id: 'staff-1-id',
            name: 'Staff 1',
          },
        }),
      };
    },
  );

  return {
    availabilityEntries,
  };
}
